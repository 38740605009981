import instance from "@/utils/request";

// 获取会话列表
export function getSessionList() {
    return instance.get('/api/v1/conversation/recent-list');
}

// 查询用户ID
export function getUserId(value:any) {
    return instance.get('/api/v1/user/by-username',{
        params:value
    });
}

// 查询是否有客服群聊
export function queryCustomerGroup() {
    return instance.get('/api/v1/group/customer-group-info');
}

// 创建客服群聊
export function createCustomerGroup(value:any) {
    return instance.post('/api/v1/group/create-group',value);
}

// 获取产品ID和企业ID
export function GetIds(value:any) {
    return instance.get('/api/v1/product/by-app-key',{
        params:value
    });
}