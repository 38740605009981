export default {
    'contact.social': 'ติดต่อบัญชีโซเชียลอย่างเป็นทางการ',
    'contact.customer': 'ติดต่อฝ่ายบริการลูกค้าออนไลน์',

    '201.title': 'คำถามที่พบบ่อย FAQ',
    '302.title': 'ไม่มีเจ้าหน้าที่บริการออนไลน์',
    '302.leave.message': 'ฝากข้อความออนไลน์',
    '303.title': 'เจ้าหน้าที่บริการทั้งหมดไม่ว่าง',
    '304.title': 'กำลังรอคิว',
    '308.title': 'จบการสนทนาหรือไม่',
    '308.question': 'มีคำถามเพิ่มเติมหรือไม่',
    '308.finish': 'ปัญหาได้รับการแก้ไขแล้ว',
    '202.title': 'ประเมินบริการ',
    '202.poor': 'รีวิวไม่ดี',
    '202.good': 'รีวิวดี',
};