export default {
    'noformat':'このファイル形式は現在サポートされていません',
    'photograph':'写真を撮影',
    'album':'アルバム',
    'video':'ビデオ',
    'customer':'カスタマーサポート',
    'appraisalPopup.title':'カスタマーサポート評価',
    'appraisalPopup.sub_title':'サービス評価',
    'appraisalPopup.feedback':'ご意見・ご感想',
    'appraisalPopup.submit':'送信',
    'endPopup.title':'会話を終了しますか？',
    'endPopup.sub_title':'他にご質問はありますか？',
    'network':'ネットワークが不安定です。ネットワーク設定を確認してください。',
    'sendInThe':'送信中'
};