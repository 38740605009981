import chatlist from './VN/chatlist';
import returnMessage from './VN/returnMessage';
import chat from './VN/chat';
import officialPopup from './VN/officialPopup';
import faq from './VN/faq';



export default {
  ...chatlist,
  ...returnMessage,
  ...chat,
  ...officialPopup,
  ...faq
};
