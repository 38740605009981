export default {
    'noformat': 'Jenis file ini belum didukung',
    'photograph': 'Ambil foto',
    'album': 'Album',
    'video': 'Video',
    'customer': 'Layanan Pelanggan',
    'appraisalPopup.title': 'Penilaian Layanan Pelanggan',
    'appraisalPopup.sub_title': 'Penilaian Layanan',
    'appraisalPopup.feedback': 'Saran dan Masukan',
    'appraisalPopup.submit': 'Kirim',
    'endPopup.title': 'Apakah Anda ingin mengakhiri sesi chat ini?',
    'endPopup.sub_title': 'Apakah ada pertanyaan lain yang bisa saya bantu?',
    'network': 'Jaringan tidak stabil, harap periksa pengaturan jaringan Anda',
    'sendInThe': 'Mengirim...'
};