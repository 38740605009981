export default {
    'contact.social':' Связаться с официальной социальной сетью',
    'contact.customer':' Связаться со службой поддержки в Интернете',

    '201.title':' Часто задаваемые вопросы FAQ',
    '302.title':' Нет службы поддержки в настоящее время',
    '302.leave.message':' Оставить сообщение онлайн',
    '303.title':' Все службы поддержки заняты',
    '304.title':' Ожидание в очереди',
    '308.title':' Завершить сеанс?',
    '308.question':' Есть другие вопросы?',
    '308.finish':' Проблема решена',
    '202.title':' Оценка услуг',
    '202.poor':' Отрицательный отзыв',
    '202.good': 'Положительный отзыв'

};