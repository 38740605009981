import { socketSend } from './im';
import { getCheckSendStatus } from '@/api/chat';

// 消息重发机制 --- 缓存栈阶段 --- 缓存重试机制缓存栈
function MsgCache(msg_incr:string,Msg:string){
    let MsgResendCache = JSON.parse(sessionStorage.getItem('MsgResendCache') as string);
    if(MsgResendCache){
        MsgResendCache = [...MsgResendCache,{timestamp:new Date().getTime(),mark:msg_incr,content:Msg,retry:0}]
    }else{
        MsgResendCache = [{timestamp:new Date().getTime(),mark:msg_incr,content:Msg,retry:0}];
    }
    sessionStorage.setItem('MsgResendCache',JSON.stringify(MsgResendCache));
}

// 消息重发机制 --- 服务端已确认阶段 --- 清掉重试消息缓存栈
function MsgConfirm(msg_incr:string){
    let MsgResendCache = JSON.parse(sessionStorage.getItem('MsgResendCache') as string);
    MsgResendCache.forEach((val:any,i:any)=>{
        if(val.mark === msg_incr){
            MsgResendCache.splice(i,1);
            return;
        }
    })
    sessionStorage.setItem('MsgResendCache',JSON.stringify(MsgResendCache));
}

// 消息重发机制 --- 消息重发
function MsgResend(){
    sessionStorage.setItem('NeedResend','false');   // 上一轮消息重发是否执行完成
    let MsgResendCache = JSON.parse(sessionStorage.getItem('MsgResendCache') as string);
    if(MsgResendCache){
        MsgResendCache.forEach((val:any,i:any)=>{
            if(new Date().getTime() - val.timestamp >= 3000 && val.retry < 1){
                getCheckSendStatus({client_msg_id:val.mark}).then(res=>{
                    if(res.data.is_send === false){
                        val.retry = val.retry + 1;
                        socketSend(val.content);
                    }else if(res.data.is_send === true){
                        MsgConfirm(val.mark);
                    };
                    sessionStorage.setItem('NeedResend','true');    // 上一轮消息重发是否执行完成
                })
            }else{
                return;
            }
        })
        sessionStorage.setItem('MsgResendCache',JSON.stringify(MsgResendCache));
    }
}





export {
    MsgCache,
    MsgConfirm,
    MsgResend
}