export default {
    'contact.social': '联系官方社交账号',
    'contact.customer': '联系线上客服',

    '201.title': '常见问题 FAQ',
    '302.title': '当前无客服在线',
    '302.leave.message': '我要在线留言',
    '303.title': '当前所有客服忙',
    '304.title': '正在排队中',
    '308.title': '是否结束会话',
    '308.question': '还有问题',
    '308.finish': '问题已解决',
    '202.title': '服务评价',
    '202.poor': '差评',
    '202.good': '好评'
};