export default {
    'noformat': '暂不支持该文件类型',
    'photograph': '拍照',
    'album': '相册',
    'video': '视频',
    'customer': '客服',
    'appraisalPopup.title': '评价客服',
    'appraisalPopup.sub_title': '服务评分',
    'appraisalPopup.feedback': '意见反馈',
    'appraisalPopup.submit': '提交',
    'endPopup.title': '是否结束会话',
    'endPopup.sub_title': '您还有什么问题吗?',
    'network': '当前网络不稳定，请检查你的网络设置',
    'sendInThe': '发送中'
};