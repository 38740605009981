export default {
    'noformat': 'This file type is not supported temporarily',
    'photograph': 'photograph',
    'album': 'album',
    'video': 'video',
    'customer': 'Customer service',
    'appraisalPopup.title': 'Evaluation',
    'appraisalPopup.sub_title': 'Service score',
    'appraisalPopup.feedback': 'Feedback',
    'appraisalPopup.submit': 'Submit',
    'endPopup.title': 'Whether to end the session',
    'endPopup.sub_title': 'Do you have any questions?',
    'network': 'The current network is not stable',
    'sendInThe': 'Send in'
};