import { ref } from "@vue/reactivity";
import { getJsUrl } from '@/utils/function';
import { createI18n } from 'vue-i18n';
import CN from './CN';
import US from './US';
import BR from './BR';
import ID from './ID';
import JP from './JP';
import RU from './RU';
import TH from './TH';
import VN from './VN';
import NG from './NG';

var route:any;

if(location.href.indexOf('?') !== -1){
    route = getJsUrl(location.href);
}

if(route && route.country){
    localStorage.setItem('locale-language',route.country);
}else{
    const country = localStorage.getItem('locale-language');
    country ? localStorage.setItem('locale-language',country) : localStorage.setItem('locale-language','US');
}
// if(route.country){localStorage.setItem('locale-language',route.country);};      // 语言

export const LOCALE_OPTIONS = [
    { label: '越南', value: 'VN' },
    { label: '意大利', value: 'IT' },
    { label: '日本', value: 'JP' },
    { label: '英国', value: 'GB' },
    { label: '美国', value: 'US' },
    { label: '巴西', value: 'BR' },
    { label: '印度尼西亚', value: 'ID' },
    { label: '印度', value: 'IN' },
    { label: '马来西亚', value: 'MY' },
    { label: '菲律宾', value: 'PH' },
    { label: '泰国', value: 'TH' },
    { label: '俄罗斯', value: 'RU' },
    { label: '墨西哥', value: 'MX' },
    { label: '哥伦比亚', value: 'CO' },
    { label: '阿根廷', value: 'AR' },
    { label: '土耳其', value: 'TR' },
    { label: '尼日利亚', value: 'NG' },
    { label: '南非', value: 'ZA' },
    { label: '肯尼亚', value: 'KE' },
    { label: '中文', value: 'CN' },
];

// const defaultLocale = ref(localStorage.getItem('locale-language') ? localStorage.getItem('locale-language') : route.country ? route.country : 'US');
const defaultLocale = localStorage.getItem('locale-language') as string;
// const defaultLocale = localStorage.getItem('locale-language') || route.country ? route.country : 'US';

const i18n = createI18n({
    locale: defaultLocale,
    fallbackLocale: 'US',
    allowComposition: true,
    messages: {
        CN,
        US,
        BR,
        ID,
        JP,
        RU,
        TH,
        VN,
        NG
    }
});
  
export default i18n;