import createPersistedState from 'vuex-persistedstate';
import { createStore } from 'vuex'
import MESSAGE from './message'   // 客服消息
import CHATLIST from './chatlist' // 聊天列表

export default createStore({
  modules: {
    MESSAGE,
    CHATLIST
  },
  plugins:[
      createPersistedState({
          storage:window.localStorage
      })
  ]
})
