export default {
    'noformat': 'Tạm thời không hỗ trợ định dạng tập tin này',
    'photograph': 'Chụp ảnh',
    'album': 'Album',
    'video': 'Video',
    'customer': 'Dịch vụ khách hàng',
    'appraisalPopup.title': 'Đánh giá dịch vụ khách hàng',
    'appraisalPopup.sub_title': 'Điểm đánh giá dịch vụ',
    'appraisalPopup.feedback': 'Phản hồi ý kiến',
    'appraisalPopup.submit': 'Gửi',
    'endPopup.title': 'Bạn có muốn kết thúc phiên trò chuyện không?',
    'endPopup.sub_title': 'Bạn còn câu hỏi nào không?',
    'network': 'Mạng không ổn định hiện tại, vui lòng kiểm tra cài đặt mạng của bạn',
    'sendInThe':'gửi'
};