import { createApp , onErrorCaptured } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './locale'
import '@/assets/css/reset.css'
import Vant from 'vant'
import 'vant/lib/index.css'
import './utils/imHandle'
import { Lazyload , ImagePreview , Loading } from 'vant';

// 23.12.12 注释了这一段
// import { getJsUrl } from './utils/function';
// if(location.href.indexOf('?') !== -1){
//     if (window.performance.navigation.type === 1) {
        
//     }else{  // 页面首次进入
//         console.log('执行了')
//         localStorage.removeItem('productID');
//         localStorage.removeItem('companyID');
//         localStorage.removeItem('userID');
//     }
//     var route = getJsUrl(location.href);
//     localStorage.setItem('NeedScroll','true');                                      // 默认需要滚动
//     if(route.productID){localStorage.setItem('productID',route.productID);};        // 产品ID
//     if(route.companyID){localStorage.setItem('companyID',route.companyID);};        // 企业ID
//     if(route.country){localStorage.setItem('locale-language',route.country);};      // 语言
//     if(route.user_id){localStorage.setItem('userID',route.user_id);};               // 第三方用户ID
// }

// window.addEventListener('error',event => {
    // console.log('错误输出',event);
// });
// onErrorCaptured((err, vm, info)=>{
    // console.log(err, vm, info)
// })


createApp(App).use(store).use(router).use(i18n).use(Vant).use(Lazyload).use(ImagePreview).use(Loading).mount('#app');