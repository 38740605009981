import chatlist from './BR/chatlist';
import returnMessage from './BR/returnMessage';
import chat from './BR/chat';
import officialPopup from './BR/officialPopup';
import faq from './BR/faq';



export default {
  ...chatlist,
  ...returnMessage,
  ...chat,
  ...officialPopup,
  ...faq
};
