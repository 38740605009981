import axiox from "@/utils/FAQrequest";
import instance from "@/utils/request";

// 获取类型列表
export function getTypeList(value:any) {
    return axiox.get('/customer/v1/faq-api/get-type',{
        params:value
    });
}

// 查找问题指引
export function getGuidance(value:any) {
    return axiox.get('/customer/v1/faq-api/find-guide',{
        params:value
    });
}

// 获取答案
export function getAnswer(value:any) {
    return axiox.get('/customer/v1/faq-api/get',{
        params:value
    });
}


// ----------------------------------------- 本项目

// 获取配置
export function getFaqConfig(value:any) {
    return instance.get('/api/v1/faq/conf',{
        params:value
    });
}

// 获取类型列表
export function getTypeListI(value:any) {
    return instance.get('/api/v1/faq/type-list',{
        params:value
    });
}

// 查找问题指引
export function getGuidanceI(value:any) {
    return instance.get('/api/v1/faq/list',{
        params:value
    });
}

// 获取答案
export function getAnswerI(value:any) {
    return instance.get('/api/v1/faq/details',{
        params:value
    });
}