import chatlist from "./NG/chatlist";
import returnMessage from "./NG/returnMessage";
import chat from "./NG/chat";
import officialPopup from './NG/officialPopup';
import faq from './NG/faq';



export default {
  ...chatlist,
  ...returnMessage,
  ...chat,
  ...officialPopup,
  ...faq
};
