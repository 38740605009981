import instance from "@/utils/request";

// 获取资源上传地址
export function getUploadUrl(value:any) {
    return instance.get('/store/v1/sign-upload-object',{
        params:value
    });
}

// 上传图片
export function uploadResource(url:any,value:any) {
    return instance.put(url,value);
}

// 登录
export function login(value:any) {
    return instance.post('/api/v1/user/login',value);
}

// 用户信息
export function getUserInfo() {
    return instance.get('/api/v1/user/info');
}