import instance from "@/utils/request";

// 查询消息是否发送成功
export function getCheckSendStatus(value:any) {
    return instance.get('/api/v1/msg/check-send-status',{
        params:value
    });
}

// 获取历史会话
export function getHistorylist(value:any) {
    return instance.get('/api/v1/msg/history-list',{
        params:value
    });
}

// 接待评价
export function evaluation(value:any) {
    return instance.post('/api/v1/reception/submit-evaluate',value);
}

// 获取群成员
export function GetGroupMember(value:any) {
    return instance.get('/api/v1/group/member-list',{
        params:value
    });
}

// 获取对聊用户信息
export function GetChatUserInfo(value:any) {
    let query = `ids=${value.join('&ids=')}`;
    return instance.get(`/api/v1/user/find-by-ids?${query}`);
}