export default {
    'contact.social': 'Contact social account',
    'contact.customer': 'Contact online customer service',

    '201.title': 'Common problem FAQ',
    '302.title': 'No customer service online',
    '302.leave.message': 'leave a message',
    '303.title': 'All customer service is busy',
    '304.title': 'In line',
    '308.title': 'Whether to end the session',
    '308.question': 'There are still problems',
    '308.finish': 'problem solved',
    '202.title': 'service comment',
    '202.poor': 'Bad',
    '202.good': 'Praise'
};