export default {
    'contact.social': 'Kontak akun media sosial resmi',
    'contact.customer': 'Hubungi layanan pelanggan online',
    
    '201.title': 'Pertanyaan yang Sering Diajukan FAQ',
    '302.title': 'Tidak ada layanan pelanggan yang tersedia saat ini',
    '302.leave.message': 'Saya ingin meninggalkan pesan',
    '303.title': 'Semua layanan pelanggan sedang sibuk saat ini',
    '304.title': 'Anda sedang dalam antrian',
    '308.title': 'Apakah Anda ingin mengakhiri sesi chat ini?',
    '308.question': 'Masih ada pertanyaan lain?',
    '308.finish': 'Pertanyaan sudah terjawab',
    '202.title': 'Penilaian Layanan',
    '202.poor': 'Tidak Baik',
    '202.good': 'Baik'
};