import chatlist from './RU/chatlist';
import returnMessage from './RU/returnMessage';
import chat from './RU/chat';
import officialPopup from './RU/officialPopup';
import faq from './RU/faq';



export default {
  ...chatlist,
  ...returnMessage,
  ...chat,
  ...officialPopup,
  ...faq
};
