import chatlist from "./US/chatlist";
import returnMessage from "./US/returnMessage";
import chat from "./US/chat";
import officialPopup from './US/officialPopup';
import faq from './US/faq';



export default {
  ...chatlist,
  ...returnMessage,
  ...chat,
  ...officialPopup,
  ...faq
};
