import chatlist from './CN/chatlist';
import returnMessage from './CN/returnMessage';
import chat from './CN/chat';
import officialPopup from './CN/officialPopup';
import faq from './CN/faq';



export default {
  ...chatlist,
  ...returnMessage,
  ...chat,
  ...officialPopup,
  ...faq
};
