export default {
    'contact.social': 'Liên hệ tài khoản mạng xã hội chính thức',
    'contact.customer': 'Liên hệ dịch vụ khách hàng trực tuyến',

    '201.title': 'Câu hỏi thường gặp (FAQ)',
    '302.title': 'Không có dịch vụ khách hàng trực tuyến hiện tại',
    '302.leave.message': 'Tôi muốn để lại tin nhắn trực tuyến',
    '303.title': 'Tất cả các dịch vụ khách hàng đều bận',
    '304.title': 'Đang chờ đợi',
    '308.title': 'Bạn có muốn kết thúc phiên trò chuyện không?',
    '308.question': 'Có câu hỏi nào khác không?',
    '308.finish': 'Vấn đề đã được giải quyết',
    '202.title': 'Đánh giá dịch vụ',
    '202.poor': 'Đánh giá thấp',
    '202.good': 'Đánh giá cao'
};