export default {
    'contact.social':'contatar conta social',
    'contact.customer': 'Entre em contato com o atendimento ao cliente on-line',

    '201.title': 'Perguntas frequentes sobre problemas comuns',
    '302.title': 'Sem atendimento ao cliente online',
    '302.leave.message': 'Deixe um recado',
    '303.title': 'Todo o atendimento ao cliente está ocupado',
    '304.title': 'Em linha',
    '308.title': 'Se deve terminar a sessão',
    '308.question': 'Ainda há problemas',
    '308.finish': 'problema resolvido',
    '202.title': 'comentário de serviço',
    '202.poor': 'Ruim',
    '202.good': 'Louvar'
};