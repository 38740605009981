export default {
    namespaced:true,
    state: {
        chatList:[
            
        ]
      },
      mutations: {
        // 替换数据
        onSetStatechatList(state:any,value:any){
            state.chatList = value;
        },
        onReset(state:any){
            state.chatList = [];
        }
      },
}

// {
//     opposite_id:'23756956047840',
//     name:'用户A',
//     chat_type:1,
//     unread_num:0,
//     last_msg_id:'',
//     last_msg_at:'2022-05-20 17:20',
//     last_msg:'最后一次的消息'
// }