export default {
    'noformat':'Este tipo de arquivo não é suportado temporariamente',
    'photograph': 'fotografia',
    'album': 'álbum',
    'video': 'vídeo',
    'customer': 'Atendimento ao Cliente',
    'appraisalPopup.title': 'Avaliação',
    'appraisalPopup.sub_title': 'Pontuação de serviço',
    'appraisalPopup.feedback': 'Opinião',
    'appraisalPopup.submit': 'Enviar',
    'endPopup.title': 'Se deve terminar a sessão',
    'endPopup.sub_title': 'Você tem alguma pergunta?',
    'network': 'A rede atual não é estável',
    'sendInThe': 'Enviar'
};