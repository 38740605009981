import chatlist from './TH/chatlist';
import returnMessage from './TH/returnMessage';
import chat from './TH/chat';
import officialPopup from './TH/officialPopup';
import faq from './TH/faq';



export default {
  ...chatlist,
  ...returnMessage,
  ...chat,
  ...officialPopup,
  ...faq
};
