import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { GetIds } from '@/api/chatlist'
import { getFaqConfig } from '@/api/FAQ'
import { parseUrl } from '@/utils/function'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import('../views/chat/chat.vue')
  },
  {
    path: '/chatlist',
    name: 'chatlist',
    component: () => import('../views/chatlist/chatList.vue')
  },
  {
    path: '/faqmain',
    name: 'faqmain',
    component: () => import('../views/faq/FaqMain.vue')
  },
  {
    path: '/faqclass',
    name: 'faqclass',
    component: () => import('../views/faq/FaqClass.vue')
  },
  {
    path: '/faqreply',
    name: 'faqreply',
    component: () => import('../views/faq/FaqReply.vue')
  },
  {
    path: '/faqindex',
    name: 'faqindex',
    component: () => import('../views/faq/Faqindex.vue')
  },
  {
    path: '/faqdetail',
    name: 'faqdetail',
    component: () => import('../views/faq/Faqdetail.vue')
  },
  {
    path: '/faqanswery',
    name: 'faqanswery',
    component: () => import('../views/faq/Faqanswery.vue')
  },
  {
    path: '/faqa',
    name: 'faqa',
    component: () => import('../views/faq/FaqA.vue')
  },
  {
    path: '/faqb',
    name: 'faqb',
    component: () => import('../views/faq/FaqB.vue')
  },
  {
    path: '/social',
    name: 'social',
    component: () => import('../views/social/social.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/notFound/notfound.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name:'notfound',
    redirect:'/404'
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})



router.beforeEach(async (to:any, from:any) => {
  if(to.query.account_id){
    sessionStorage.setItem('account_id',to.query.account_id);
  }
  if(to.query.distinct_id){
    sessionStorage.setItem('distinct_id',to.query.distinct_id);
  }

  if(to.name === 'faqindex' || to.name === 'faqdetail' || to.name === 'faqanswery'){
    return true;
  }
  
  // if(to.query.user && to.query.pass && !to.query.nofaq){
  //   let isGoFAQ = await getFaqConfig({app_key:to.query.app_key,country:to.query.country});
  //   if(isGoFAQ.data.is_force_faq === 1){
  //     sessionStorage.setItem('service_link',location.href);
  //     router.replace({
  //       path:'/faqmain',
  //       query:parseUrl(isGoFAQ.data.faq_url)
  //     });
  //   };
  // }

  if(to.query.user && to.query.pass || to.query.account_id && to.query.pass){
    if(!to.query.nofaq){
      let isGoFAQ = await getFaqConfig({app_key:to.query.app_key,country:to.query.country});
      sessionStorage.setItem('is_force_faq',isGoFAQ.data.is_force_faq);       //是否强制跳转到FAQ
      sessionStorage.setItem('is_report_data',isGoFAQ.data.is_report_data);   //是否上报数据
      if(isGoFAQ.data.is_force_faq === 1){
        sessionStorage.setItem('service_link',location.href);
        if(isGoFAQ.data.color_scheme >= '3'){
          router.replace({
            path:'/faqa',
            query:parseUrl(isGoFAQ.data.faq_url)
          });
        }else{
          router.replace({
            path:'/faqmain',
            query:parseUrl(isGoFAQ.data.faq_url)
          });
        }
      };
    }
  }

  let productID = localStorage.getItem('productID');
  let companyID = localStorage.getItem('companyID');
  if(productID && companyID){
    return true;
  }else{
    let res = await GetIds({key:to.query.app_key});
    localStorage.setItem('productID',res.data.id);
    localStorage.setItem('companyID',res.data.company_id);
    let productID = localStorage.getItem('productID');
    let companyID = localStorage.getItem('companyID');

    if(productID && companyID){
      return true;
    }else{
      return false;
    }
  }

  // let productID = localStorage.getItem('productID');
  // let companyID = localStorage.getItem('companyID');
  // if(productID && companyID){
  //   return true;
  // }else{
  //     if(to.name === 'faqindex' || to.name === 'faqdetail' || to.name === 'faqanswery'){
  //       return true;
  //     }else{
  //       let res = await GetIds({key:to.query.app_key});
  //       localStorage.setItem('productID',res.data.id);
  //       localStorage.setItem('companyID',res.data.company_id);
  //       let productID = localStorage.getItem('productID');
  //       let companyID = localStorage.getItem('companyID');

  //       if(!to.query.nofaq){
  //         let isGoFAQ = await getFaqConfig({app_key:to.query.app_key,country:to.query.country});
  //         if(isGoFAQ.data.is_force_faq === 1){
  //           sessionStorage.setItem('service_link',location.href);
  //           router.replace({
  //             path:'/faqmain',
  //             query:parseUrl(isGoFAQ.data.faq_url)
  //           });
  //         };
  //       }

  //       if(productID && companyID){
  //         return true;
  //       }else{
  //         return false;
  //       }
  //     }
  // }
})

export default router