import chatlist from './ID/chatlist';
import returnMessage from './ID/returnMessage';
import chat from './ID/chat';
import officialPopup from './ID/officialPopup';
import faq from './ID/faq';



export default {
  ...chatlist,
  ...returnMessage,
  ...chat,
  ...officialPopup,
  ...faq
};
