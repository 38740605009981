import axios from 'axios';

let mode = 'pro';
let url = '';

if(mode === 'dev'){
    url = 'http://192.168.0.65:8000';
}else if(mode === 'pro'){
    url = 'https://faq.mangguozi.com';
}

let instance = axios.create({
    baseURL:url,
    headers:{
        'Authorization':'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjM3NTY5NTYwNDc4NDAsImdyYW50X3R5cGUiOiJwYXNzd29yZCIsInByb2R1Y3RfaWQiOjIsImNvbXBhbnlfaWQiOjIsInVzZXJuYW1lIjoiYWRtaW4iLCJyb2xlIjoxLCJpc3MiOiJtaWNoYXQiLCJleHAiOjE2NjY0MjU2MTMsIm5iZiI6MTY2MTI0MTYxM30.YB6TuhZGl5Un-ywxb0fRNOQDsrT2zKKrViAmoJpw_YA',
        'CompanyId':'2'
    }
})

instance.interceptors.request.use(function (config) {
    return config;
}, function (error) {
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    return Promise.reject(error);
});

export default instance;