// @ts-nocheck

import { Toast } from 'vant';
import { UID } from './imHandle.js';



// URL地址解析
export function getJsUrl(url:any):any{
	var pos,str,para,parastr; 
	var array:any =[]
	str = location.href; 
	parastr = str.split("?")[1]; 
	var arr = parastr.split("&");
	for (var i=0;i<arr.length;i++){
        array[arr[i].split("=")[0]]=arr[i].split("=")[1];
    }
    return array;
}

// URL地址解析
export function parseUrl(url:any):any{
	var pos,str,para,parastr; 
	var array:any =[]
	str = url; 
	parastr = str.split("?")[1]; 
	var arr = parastr.split("&");
	for (var i=0;i<arr.length;i++){
        array[arr[i].split("=")[0]]=arr[i].split("=")[1];
    }
    return array;
}

export function compare(property:any){
    return function(a:any,b:any){
        var value1 = a[property];
        var value2 = b[property];
        return value1 - value2;
    }
}

export function copy(data:any) {
    let OrderNumber = data;
    let newInput = document.createElement("input");
    newInput.value = OrderNumber;
    document.body.appendChild(newInput);
    newInput.select();
    document.execCommand("Copy");
    newInput.remove();
    // Toast('提示内容');
}

// 获取UID
export function getUid() {
    let uid = localStorage.getItem('uid');
    return UID || uid;
}