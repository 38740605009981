import chatlist from './JP/chatlist';
import returnMessage from './JP/returnMessage';
import chat from './JP/chat';
import officialPopup from './JP/officialPopup';
import faq from './JP/faq';



export default {
  ...chatlist,
  ...returnMessage,
  ...chat,
  ...officialPopup,
  ...faq
};
