export default {
    'noformat':'ไม่รองรับประเภทไฟล์นี้ชั่วคราว',
    'photograph':'ถ่ายรูป',
    'album':'อัลบั้มภาพ',
    'video':'วิดีโอ',
    'customer':'บริการลูกค้า',
    'appraisalPopup.title':'ให้คะแนนบริการลูกค้า',
    'appraisalPopup.sub_title':'การให้บริการให้คะแนน',
    'appraisalPopup.feedback':'คำแนะนำและความคิดเห็น',
    'appraisalPopup.submit':'ส่ง',
    'endPopup.title':'จบการสนทนาหรือไม่',
    'endPopup.sub_title':'คุณมีคำถามอื่น ๆ อีกหรือไม่?',
    'network':'เครือข่ายไม่เสถียรในขณะนี้ โปรดตรวจสอบการตั้งค่าเครือข่ายของคุณ',
    'sendInThe':'กำลังส่ง'
};