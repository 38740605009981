export default {
    'contact.social':'公式のソーシャルアカウントに連絡する',
    'contact.customer':'オンラインカスタマーサポートに連絡する',

    '201.title':'よくある質問FAQ',
    '302.title':'現在、カスタマーサポートがオフラインです',
    '302.leave.message':'オンラインメッセージを送信する',
    '303.title':'現在、すべてのカスタマーサポートがビジー状態です',
    '304.title':'キューに並んでいます',
    '308.title':'セッションを終了しますか？',
    '308.question':'他に何かありますか？',
    '308.finish':'問題が解決しました',
    '202.title':'サービス評価',
    '202.poor':'悪い評価',
    '202.good':'良い評価'
};